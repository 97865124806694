"use client";

import {ComposableMap, Geographies, Geography, Marker} from "react-simple-maps";

// Define an interface for location data
interface Location {
  coordinates: [number, number];
  city: string;
  country: string;
}

// Define an array of location data with type annotations
const locations: Location[] = [
  {coordinates: [-122.4194, 37.7749], city: "San Francisco", country: "USA"},
  {coordinates: [-74.006, 40.7128], city: "New York", country: "USA"},
  {coordinates: [-79.3832, 43.6532], city: "Toronto", country: "Canada"},
  {coordinates: [-123.1216, 49.2827], city: "Vancouver", country: "Canada"},
  {coordinates: [-0.1278, 51.5074], city: "London", country: "UK"},
  {coordinates: [55.2962, 25.2769], city: "Dubai", country: "UAE"},
  {coordinates: [114.1694, 22.3193], city: "Hong Kong", country: "China"},
  {coordinates: [139.6917, 35.6895], city: "Tokyo", country: "Japan"},
  {coordinates: [-43.1729, -22.9068], city: "Rio de Janeiro", country: "Brazil"},
  {coordinates: [-97.7431, 30.2672], city: "Austin", country: "USA"},
  {coordinates: [-80.1919, 25.7619], city: "Miami", country: "USA"},
  {coordinates: [2.1734, 41.3851], city: "Barcelona", country: "Spain"},
  {coordinates: [133.7751, -25.2744], city: "Australia", country: "Australia"},
  {coordinates: [13.405, 52.52], city: "Berlin", country: "Germany"},
  {coordinates: [2.3522, 48.8566], city: "Paris", country: "France"},
  {coordinates: [9.19, 45.4642], city: "Milan", country: "Italy"},
  {coordinates: [71.429, 51.169], city: "Astana", country: "Kazakhstan"},
  {coordinates: [116.4074, 39.9042], city: "Beijing", country: "China"},
  {coordinates: [103.8198, 1.3521], city: "Singapore", country: "Singapore"},
  {coordinates: [34.7818, 32.0853], city: "Tel Aviv", country: "Israel"},
  {coordinates: [-58.4173, -34.6118], city: "Buenos Aires", country: "Argentina"},
  {coordinates: [44.5236, 40.1833], city: "Yerevan", country: "Armenia"},
  {coordinates: [6.1432, 46.2044], city: "Geneva", country: "Switzerland"},
  {coordinates: [100.9925, 15.87], city: "Bangkok", country: "Thailand"},
  {coordinates: [78.9629, 20.5937], city: "New Delhi", country: "India"},
  {coordinates: [18.4241, -33.9249], city: "Cape Town", country: "South Africa"},
  {coordinates: [24.7535, 59.437], city: "Tallinn", country: "Estonia"},
  {coordinates: [37.6173, 55.7558], city: "Moscow", country: "Russia"},
  {coordinates: [-99.1332, 19.4326], city: "Mexico City", country: "Mexico"},
  {coordinates: [-9.1393, 38.7223], city: "Lisbon", country: "Portugal"}
];

// TopoJSON URL for the world map data
const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-50m.json";

// Memoized Marker Component

const WorldMap: React.FC = () => {
  return (
    <div className="text-primary-gridient" style={{backgroundColor: "#030712", padding: "20px", borderRadius: "8px"}} data-aos="fade-up">
      <ComposableMap projectionConfig={{scale: 200}} style={{width: "100%", height: "auto"}}>
        <defs>
          <linearGradient id="animatedGradient" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor="#c7d2fe">
              <animate attributeName="stop-color" values="#c7d2fe; #a5b4fc; #c7d2fe" dur="6s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" stopColor="#a5b4fc">
              <animate attributeName="stop-color" values="#a5b4fc; #818cf8; #a5b4fc" dur="6s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
        </defs>

        <defs>
          <radialGradient id="gradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
            <stop offset="0%" stopColor="#8fe548" stopOpacity="1" />
            <stop offset="100%" stopColor="#4caf50" stopOpacity="1" />
          </radialGradient>
        </defs>

        <Geographies geography={geoUrl}>
          {({geographies}) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                style={{
                  default: {fill: "url(#animatedGradient)", outline: "none"}, // Darker land color
                  hover: {fill: "#8fe548", outline: "none"}, // Accent color on hover
                  pressed: {fill: "#8fe548", outline: "none"}
                }}
              />
            ))
          }
        </Geographies>
        {locations.map(({coordinates, city}, index) => (
          <Marker key={index} coordinates={coordinates}>
            <circle r={6} fill="url(#gradient)" className="pulse-marker"></circle>
            <text textAnchor="middle" y={-12} className="map-city-text">
              {city}
            </text>
          </Marker>
        ))}
      </ComposableMap>
    </div>
  );
};

export default WorldMap;

"use client";

import React, {useState} from "react";
import Image from "next/image";

const ContactFormSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(formData)
      });

      const result = await response.json();
      if (result.success) {
        setSuccess(true);
      } else {
        console.error("Email sending error:", result.message);
      }
    } catch (error) {
      console.error("Form submission error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="rounded-lg mx-3 xl:mx-10 mb-10 mt-44" style={{backgroundImage: "url(img/wave-pattern-light.svg)"}} id="contactFormIdentity">
      <div className="container mx-auto py-8 px-4 md:px-8 lg:px-10">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:w-5/12 mb-20 lg:mb-0 ">
            <h3 className="bg-clip-text text-transparent font-semibold text-2xl text-primary-gridient" data-aos="fade-up">
              Let&apos;s Shape the Future Together
            </h3>

            <div className="border-t border-opacity-10 border-white my-5 max-w-xs"></div>

            <figure data-aos="fade-up">
              <blockquote className="italic font-light">
                &quot;Welcome to MOZG! We&apos;re thrilled to connect with visionaries like you. Share your ideas with us, and let&apos;s embark on a journey to turn groundbreaking concepts into reality. Fill
                out the form below to begin crafting your next project with our team of experts. Your innovation starts here.&quot;
              </blockquote>
              <figcaption className="mt-5 flex items-center">
                <Image width={160} height={160} className="w-16 h-16 rounded-full" src="/img/160x160/mozgii_ceo_avatar_3.jpg" alt="CEO Avatar" />
                <div className="ml-3 text-white">
                  <p className="font-semibold uppercase">Mike MOZG</p>
                  <span className="text-sm">CEO & Founder</span>
                </div>
              </figcaption>
            </figure>
          </div>

          <div className="lg:w-1/2">
            <div className="bg-card-dark-2 p-6 rounded-lg shadow-md" data-aos="fade-up">
              <div className="text-white mb-4">
                {success ? (
                  <h3>We&apos;ll be in touch within 24 hours.</h3>
                ) : (
                  <h3 className="bg-clip-text text-transparent text-primary-gridient-2 font-semibold text-xl">Submit the form - we&apos;ll be in touch within 24 hours to ignite your request.</h3>
                )}
              </div>

              {!success && (
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="name" className="sr-only">
                      Full Name
                    </label>
                    <input type="text" name="name" id="name" value={formData.name} onChange={handleChange} className="w-full px-4 py-3 bg-gray-800 text-white rounded-md" placeholder="Full Name" required />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className="sr-only">
                      Email
                    </label>
                    <input type="email" name="email" id="email" value={formData.email} onChange={handleChange} className="w-full px-4 py-3 bg-gray-800 text-white rounded-md" placeholder="Email" required />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="phone" className="sr-only">
                      Phone
                    </label>
                    <input type="text" name="phone" id="phone" value={formData.phone} onChange={handleChange} className="w-full px-4 py-3 bg-gray-800 text-white rounded-md" placeholder="Phone" required />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="message" className="sr-only">
                      Message
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="w-full px-4 py-3 bg-gray-800 text-white rounded-md resize-none"
                      placeholder="Message"
                      rows={4}
                      required></textarea>
                  </div>
                  <div>
                    {isLoading ? (
                      <button type="button" className="w-full bg-primary py-3 rounded-md" disabled>
                        <span className="loader spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        Submitting...
                      </button>
                    ) : (
                      <button type="submit" className="w-full bg-gray-800 py-3 rounded-md text-white hover:bg-opacity-80 hover:bg-gray-600 transition-all duration-300">
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFormSection;
